import type { ColumnDef, Row } from "@tanstack/react-table";
import type { AppDispatch } from "src/store/store";
import { createColumnHelper } from "@tanstack/react-table";
import { useDispatch } from "react-redux";
import {
  Button,
  CollapsibleReactTable,
  FactoringModal,
  Icon,
  P,
  PayeeModal,
  Tag,
  Loader,
} from "src/components";
import { useCompany } from "src/store/slices/companySlice";
import {
  setSelectedCompanyByCompanyName,
} from "src/store/slices/invoicesSlice";
import {
  getDateLabelByDay,
  getDateRanges,
  getDisplayValue,
} from "src/utils/HelperFunctions";
import {
  AddressOData,
  transformInvoicesByAllInvoices,
} from "src/utils/transformers/invoiceDataTransformer";
import { setModalOpen } from "src/store/slices/modalSlice";
import InvoicesSelectorModal from "../../InvoicesSelectorModal/InvoicesSelectorModal";
import { useGetInvoiceODataQuery } from "src/store/extendedApi";
import getSymbolFromCurrency from "currency-symbol-map";

type ClientData = {
  customerTaxNumber?: string;
  address?: AddressOData;
  isPartiallyFinanced?: boolean;
  action?: "factoring" | "payee" | "";
};

export type Data = {
  invoiceNumber?: string;
  customerName?: string;
  issueDate?: Date;
  paymentDate?: Date;
  expiracy?: string;
  status?: string;
  netValue?: number;
  currency?: string;
  collapsible: ClientData;
};

interface Props {
  filter: number;
  sort: boolean;
}

const columnHelper = createColumnHelper<Data>();

const clientColumns = [
  columnHelper.accessor("invoiceNumber", {
    header: () => <span className="flex justify-start">Számla sorszám</span>,
    cell: (row) => (
      <P className="text-sm md:text-base line-clamp-1 break-all font-bold">
        {row.getValue()}
        <span className="tooltip-contents">{row.getValue()}</span>
      </P>
    ),
  }),
  columnHelper.accessor("customerName", {
    header: () => "Vevő neve",
    cell: (row) => (
      <div className="flex flex-row gap-x-2 items-center">
        <P className="text-sm md:text-base whitespace-nowrap md:whitespace-normal break-all line-clamp-1 text-black/60">
          {row.getValue()}
          <span className="tooltip-contents !break-all">{row.getValue()}</span>
        </P>
      </div>
    ),
  }),
  columnHelper.accessor("issueDate", {
    header: () => "Kelte",
    cell: (row) => (
      <P className="text-sm md:text-base break-all line-clamp-1 font-bold !text-font-main/[0.6]">
        {getDateLabelByDay(row.getValue())}
        <span className="tooltip-contents">
          {getDateLabelByDay(row.getValue())}
        </span>
      </P>
    ),
  }),
  columnHelper.accessor("paymentDate", {
    header: () => "Fiz. hat.",
    cell: (row) => (
      <P className="text-sm md:text-base break-all line-clamp-1 font-bold !text-font-main/[0.6]">
        {getDateLabelByDay(row.getValue())}
        <span className="tooltip-contents">
          {getDateLabelByDay(row.getValue())}
        </span>
      </P>
    ),
  }),
  columnHelper.accessor("expiracy", {
    header: () => "Lejárat",
    cell: (row) => (
      <P className="text-sm md:text-base whitespace-nowrap md:whitespace-normal line-clamp-1 font-bold !text-font-main/[0.6]">
        {row.getValue()}
        <span className="tooltip-contents">{row.getValue()}</span>
      </P>
    ),
  }),
  columnHelper.accessor("status", {
    header: () => "Státusz",
    cell: (row) => (
      <P className="text-sm md:text-base line-clamp-1">
        {row.getValue().length > 0 && (
          <Tag
            color={
              row.getValue() === "Finanszírozott" ||
                row.getValue() === "Folyamatban"
                ? "orange"
                : "green"
            }
            className="flex gap-x-1 items-center"
          >
            <Icon
              icon={
                row.getValue() === "Finanszírozott" ||
                  row.getValue() === "Térült"
                  ? "flexibill"
                  : "payee"
              }
              size={16}
              className="-mt-[2px]"
            />
            {row.getValue()}
          </Tag>
        )}
      </P>
    ),
  }),
  columnHelper.accessor("netValue", {
    header: () => <div className="text-right">Összeg</div>,
    cell: (row) => {
      return (
        <div className="flex gap-2 justify-end pl-2 py-1">
          <P className="md:text-base text-sm font-700">
            {getDisplayValue(row.getValue(), row.row.original.currency)}
          </P>
          <P className="md:text-base text-sm font-500 opacity-60">
            {row.row.original.currency &&
              getSymbolFromCurrency(row.row.original.currency)}
          </P>
        </div>
      );
    },
  }),
] as ColumnDef<unknown, any>[];

const CollapsibleRow = ({ row }: { row: Row<Data> }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { customerTaxNumber, address, action, isPartiallyFinanced } =
    row.original.collapsible;
  const { invoiceNumber, status, customerName } = row.original;

  const actionHandler = (
    customerName: string,
    customerTaxNumber: string,
    address: AddressOData,
    action: "factoring" | "payee"
  ) => {
    dispatch(
      setSelectedCompanyByCompanyName({
        customerName,
        customerTaxNumber,
        address,
        action,
      })
    );
    dispatch(
      setModalOpen(
        action === "factoring"
          ? "factoring-start-modal"
          : "invoices-selector-modal"
      )
    );
  };

  return (
    <div className="flex flex-row justify-between gap-x-5 p-4 w-full rounded-lg">
      <div className="flex gap-5">
        <div>
          <h6 className="uppercase text-xs text-black/50">Adószám</h6>
          <P className="line-clamp-1 text-sm md:text-base ">
            {customerTaxNumber ?? "-"}
          </P>
        </div>
        <div>
          <h6 className="uppercase text-xs text-black/50">Cím</h6>
          <P className="line-clamp-1 text-sm md:text-base ">
            {address?.City} {address?.Street} {address?.Number}
          </P>
        </div>
      </div>
      <div>
        <P className="line-clamp-1">
          <span className="flex flex-row justify-end">
            {action &&
              !isPartiallyFinanced &&
              status === "" &&
              (action as "factoring" | "payee" | "") !== "" &&
              invoiceNumber && (
                <Button
                  variant="Default"
                  className="flex gap-x-2 items-center rounded-[10px] p-2 bg-primary/5"
                  onClick={() =>
                    actionHandler(
                      customerName ?? "",
                      customerTaxNumber ?? "",
                      address ?? ({} as AddressOData),
                      action
                    )
                  }
                >
                  <Icon
                    icon={action === "factoring" ? "flexibill" : "payee"}
                    size={24}
                    className="text-primary"
                  />
                  <P className="leading-6">
                    {action === "factoring" ? "Faktorálás" : "Behajtás"}
                  </P>
                </Button>
              )}
            {isPartiallyFinanced && (
              <Tag color="primary">Részkiegyenlített</Tag>
            )}
          </span>
        </P>
      </div>
    </div>
  );
};

const AccountsReceivableManagementTableAllInvoices: React.FC<Props> = ({
  filter,
  sort,
  ...rest
}) => {
  const { company } = useCompany();
  const {
    data: invoices,
    isLoading,
    isFetching,
  } = useGetInvoiceODataQuery(
    {
      companyId: company?.id ?? "",
      filterStr: `?$filter=DueDate gt ${getDateRanges(filter as 0 | 1 | 2 | 3).start
        }T00:00:00Z and DueDate lt ${getDateRanges(filter as 0 | 1 | 2 | 3).till
        }T23:59:59Z`,
    },
    { skip: Boolean(!company?.id), refetchOnMountOrArgChange: true }
  );

  return (
    <div>
      {(isLoading || isFetching) && <Loader variant="blue" />}
      {invoices && invoices.value && invoices.value.length > 0 && (
        <>
          <div className="contents w-full overflow-auto">
            <CollapsibleReactTable
              variant="sticky"
              data={transformInvoicesByAllInvoices(
                invoices.value,
                filter,
                sort
              )}
              columns={clientColumns}
              renderCollapsibleRow={CollapsibleRow}
              {...rest}
            />
          </div>
          <InvoicesSelectorModal />
          <FactoringModal />
          <PayeeModal />
        </>
      )}
    </div>
  );
};

export default AccountsReceivableManagementTableAllInvoices;
