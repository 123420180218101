import {
  Data as DataByCompany,
  InvoiceData,
} from "src/components/organisms/AccountReceivableManagement/AccountsReceivableManagementTable/AccountsReceivableManagementTableByCo";
import { Data as DataByAllInvoices } from "src/components/organisms/AccountReceivableManagement/AccountsReceivableManagementTable/AccountsReceivableManagementTableByInvoices";
import { getTimeDeltaByDay } from "../HelperFunctions";
import { DataPointApplicationFeaturesCompaniesDtosAddressDto } from "src/store/serviceApi";

export type ByCompanyInvoiceData = {
  invoiceNumber?: string;
  date?: Date;
  paymentDate?: Date;
  expiracy?: string;
  status?: string;
  netValue?: number;
  isPartiallyFinanced?: boolean;
  action?: string;
  currency: string;
  currencyRate: number;
};

export type ByCompanyClientData = {
  customerTaxNumber?: string;
  address?: AddressOData;
  invoiceData: ByCompanyInvoiceData[];
};

export type ByCompanyData = {
  customerName?: string;
  sum: number;
  hasContract?: boolean;
  collapsible: ByCompanyClientData;
};

type ByInvoicesClientData = {
  customerTaxNumber?: string;
  address?: AddressOData;
  isPartiallyFinanced?: boolean;
  action?: "factoring" | "payee" | "";
};

export type ByInvoicesData = {
  invoiceNumber?: string;
  customerName?: string;
  issueDate?: Date;
  paymentDate?: Date;
  expiracy?: string;
  status?: string;
  netValue?: number;
  currency?: string;
  collapsible: ByInvoicesClientData;
};

export type AddressOData = {
  Country: string;
  CountryCode: string;
  Zip: string;
  City: string;
  Street: string;
  PublicDomain: string;
  Number: string;
  Building: string;
  Floor: string;
  Staircase: string;
  Door: string;
};

export type InvoiceOData = {
  InvoiceNumber: string;
  CompanyId: string;
  IssueDate: string;
  DueDate: string;
  SettlementDate: string;
  SettlementStatus: string;
  SettledAmount: number;
  ExternalStatuses: {
    FactoringStatus: string;
    CollectionStatus: string;
  };
  NetValue: number;
  Currency: string;
  CurrencyRate: number;
  Customer: {
    Name: string;
    TaxNumber: string;
    IsPrivateCustomer: boolean;
    Address: AddressOData
  };
};

// Function to help filter customers and invoices
const _handleFilter = (filter: number, status: string): boolean =>
  filter === 0 ||
  (filter === 1 && status === "factoring") ||
  (filter === 2 && status === "payee");

const _getStatus = (
  factoringStatus?: string | null,
  payeeStatus?: string | null
): string => {
  let result = "";
  if (
    factoringStatus === "REQUESTED" ||
    factoringStatus === "UPLOADED" ||
    factoringStatus === "PREPARED" ||
    factoringStatus === "PENDING_PAYMENT" ||
    factoringStatus === "PENDING_PAYMENT_TODO"
  ) {
    result = "Finanszírozott";
  }
  if (factoringStatus === "PAYMENT_DONE" || factoringStatus === "DELETED") {
    result = "Térült";
  }
  if (payeeStatus === "PENDING" || payeeStatus === "IN_PROGRESS") {
    result = "Folyamatban";
  }
  if (payeeStatus === "SUCCESS") {
    result = "Lezárult";
  }
  return result;
};

export const getAction = (
  date?: Date | null | string,
  factoringStatus?: string | null,
  payeeStatus?: string | null
): "factoring" | "payee" | "" => {
  if (!date) {
    return "";
  }
  if (
    getTimeDeltaByDay(new Date(date), new Date()) >= 0 &&
    factoringStatus === "NONE" &&
    (process.env["REACT_APP_FACTORING_ENABLED"] === "Y" ||
      process.env["REACT_APP_FACTORING_ENABLED"] === '"Y"')
  ) {
    return "factoring";
  }
  if (
    getTimeDeltaByDay(new Date(date), new Date()) < 0 &&
    payeeStatus === "NONE" &&
    (process.env["REACT_APP_PAYEE_ENABLED"] === "Y" ||
      process.env["REACT_APP_PAYEE_ENABLED"] === '"Y"')
  ) {
    return "payee";
  }
  return "";
};

const _displayExpiracyDate = (delta: number): string => {
  // TODO later maybe this will be used
  // if (delta === 0) {
  //   return "Ma";
  // } else
  if (delta >= 0) {
    return `${delta} nap`;
  } else {
    return `Lejárt ${Math.abs(delta)} napja`;
  }
};

// Function to transform original data into desired structure
export const transformInvoicesByCo = (
  invoices: any[],
  filter: number
): ByCompanyData[] => {
  const data = invoices
    ?.filter(
      (invoice: InvoiceOData) => invoice.SettlementStatus !== "Fulfilled"
    )
    .reduce((acc: ByCompanyData[], item) => {
      // Find existing customer in accumulator

      let existingCustomer = acc.find(
        (customer) => customer.customerName === item.Customer.Name
      );

      // If customer does not exist, create new one
      if (!existingCustomer) {
        existingCustomer = {
          customerName: item.Customer.Name,
          sum: 0,
          collapsible: {
            customerTaxNumber: item.Customer.TaxNumber,
            address: item.Customer.Address,
            invoiceData: [],
          },
          hasContract: false,
        };
        acc.push(existingCustomer);
      }

      const action =
        item.SettlementStatus === "Unsettled" && item.SettledAmount === 0
          ? getAction(
              item.DueDate,
              item.ExternalStatuses.FactoringStatus,
              item.ExternalStatuses.CollectionStatus
            )
          : "";
      // Add invoice data to the customer
      if (_handleFilter(filter, action)) {
        existingCustomer.collapsible.invoiceData.push({
          invoiceNumber: item.InvoiceNumber,
          date:
            typeof item.IssueDate === "string"
              ? new Date(item.IssueDate)
              : item.IssueDate,
          paymentDate: item.DueDate,
          expiracy: _displayExpiracyDate(
            getTimeDeltaByDay(item.DueDate, new Date())
          ),
          status: _getStatus(
            item.ExternalStatuses.FactoringStatus,
            item.ExternalStatuses.CollectionStatus
          ),
          netValue: item.NetValue,
          isPartiallyFinanced:
            item.SettlementStatus === "Unsettled" && item.SettledAmount > 0,
          action: action,
          currency: item.Currency ?? "",
          currencyRate: item.CurrencyRate ?? 1,
        });
      }
      const valueWithCurrency =
        (item.NetValue as number) * (item.CurrencyRate as number);

      // Update net total for the customer
      existingCustomer.sum += valueWithCurrency as number;

      return acc;
    }, []);

  for (const dataElement of data) {
    dataElement.hasContract = dataElement.collapsible.invoiceData.some(
      (d: ByCompanyInvoiceData) =>
        d.status === "Finanszírozott" || d.status === "Térült"
    );
  }
  // Filtering and removing all the empty existing costumers
  return data.filter(
    (data: ByCompanyData) => data.collapsible.invoiceData.length > 0
  );
};

export const transformInvoicesByAllInvoices = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  invoices: any[],
  filter: number,
  latestOnTop: boolean
): ByInvoicesData[] => {
  const data = invoices
    .filter((invoice: InvoiceOData) => invoice.SettlementStatus !== "Fulfilled")
    .map(
      (i: InvoiceOData) =>
        ({
          invoiceNumber: i.InvoiceNumber ?? undefined,
          customerName: i.Customer.Name ?? undefined,
          issueDate: i.IssueDate ? new Date(i.IssueDate) : undefined,
          paymentDate: i.DueDate ? new Date(i.DueDate) : undefined,
          expiracy: _displayExpiracyDate(
            getTimeDeltaByDay(i.DueDate, new Date())
          ),
          status: _getStatus(
            i.ExternalStatuses.FactoringStatus,
            i.ExternalStatuses.CollectionStatus
          ),
          netValue: i.NetValue,
          currency: i.Currency ?? "",
          collapsible: {
            customerTaxNumber: i.Customer.TaxNumber ?? undefined,
            address: i.Customer.Address,
            action:
              i.SettlementStatus === "Unsettled" && i.SettledAmount === 0
                ? getAction(
                    i.DueDate,
                    i.ExternalStatuses.FactoringStatus,
                    i.ExternalStatuses.CollectionStatus
                  )
                : "",
            isPartiallyFinanced:
              i.SettlementStatus === "Unsettled" && i.SettledAmount > 0,
          },
        } as ByInvoicesData)
    )
    .sort(function (a, b) {
      return latestOnTop
        ? new Date(b.issueDate ?? 0).valueOf() -
            new Date(a.issueDate ?? 0).valueOf()
        : new Date(a.issueDate ?? 0).valueOf() -
            new Date(b.issueDate ?? 0).valueOf();
    });

  if (data?.length === 0) return [];

  return data;
};
